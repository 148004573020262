import {
    LOGIN_ADMIN_REQUEST_SUCCESS,
    LOGIN_ERROR
} from "./actionTypes"

const INIT_STATE = {
    isAuthenticated: null,
    candidate: {},
    registrationError: null,
    registrationLoading: false
}

const Authorisation = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_ERROR:
            return state = {
                ...state,
                loading: false,
                registrationError: action.payload
            }

        case LOGIN_ADMIN_REQUEST_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                candidate: action.callBack.candidate
            }

        default:
            return state
    }
}

export default Authorisation
