import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Card,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
    FormFeedback,
} from "reactstrap"

import Dropzone from "react-dropzone";
import {notification} from 'antd';
import {useFormik} from "formik"
import * as Yup from "yup"

import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link} from "react-router-dom";
import {ErrorResult, SuccessResult} from "../../components/Notifications/Messages";


const Blogs = (props) => {

    document.title = "ივენთის დამატება"


    const [descriptionKa, setDescriptionKa] = useState(EditorState.createEmpty())
    const [descriptionEn, setDescriptionEn] = useState(EditorState.createEmpty())

    const [selectedFilesErr3, setSelectedFilesErr3] = useState(false)
    const [selectedFiles, setselectedFiles] = useState([])

    const onEditorStateChangeDescriptionKa = editorState => {
        setDescriptionKa(editorState)
    }
    const onEditorStateChangeDescriptionEn = editorState => {
        setDescriptionEn(editorState)
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    function handleAcceptedFiles(files) {
        files.map(file => {
                if (file.size >= 3022117) {
                    setSelectedFilesErr3(true)
                    setselectedFiles([])
                    return false
                } else {
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        formattedSize: formatBytes(file.size),
                    })
                }
            }
        )
        setselectedFiles(files)
    }


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            newPassword: '',

        },
        validationSchema: Yup.object({
            newPassword: Yup.string().required("შეიყვანეთ ახალი პაროლი"),
        }),
        onSubmit: (values) => {
            let item = {
                newPassword: values.newPassword,
            }
            changePassword(item)
        }
    })


    async function changePassword(item) {
        let jwt = localStorage.getItem("token")
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api3/auth/changePassword`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`,
                },
                body: JSON.stringify(item)
            });
            const Data = await response.json();

            if (!Data.success) {

            } else {
                SuccessResult('success')
            }

        } catch (error) {
            throw error;
        } finally {

        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="" breadcrumbItem="პაროლის შეცვლა"/>

                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                    >

                        <Row>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label className="form-label">ახალი პაროლი</Label>
                                    <Input
                                        name="newPassword"
                                        className="form-control"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.newPassword || ""}
                                        invalid={
                                            validation.touched.newPassword && validation.errors.newPassword ? true : false
                                        }
                                    />
                                    {validation.touched.newPassword && validation.errors.newPassword ? (
                                        <FormFeedback
                                            type="invalid">{validation.errors.newPassword}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <button
                                    className="btn btn-primary w-100 waves-effect waves-light"
                                    type="submit"
                                >
                                    პაროლის შეცვლა
                                </button>
                            </Col>
                        </Row>
                    </Form>


                </Container>
            </div>
        </React.Fragment>
    );


}

export default Blogs

