import PropTypes from 'prop-types'
import React, { useEffect } from "react"

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

// Redux
import {Link, useNavigate} from "react-router-dom"
import withRouter from '../../components/Common/withRouter';

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser,socialLogin } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-dark.png"
import {loginAdmin} from "../../store/authorization/actions";
const Login = (props) => {

  document.title=" Login | CRM"

  const history = useNavigate();
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "admin"  ,
      password:  'asdf',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Enter your email"),
      password: Yup.string().required("Enter your password"),
    }),
    onSubmit: (values) => {
      let item = {
        values,
        history : history
      }
      dispatch(loginAdmin(item));
    }
  });

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <Link to="/" className="mb-5 d-block auth-logo">
                    <img src={logo} alt="" height="62" className="logo logo-dark" />
                  </Link>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card>
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-warning">Sign In</h5>
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                      >
                        {/*{error ? <Alert color="danger">{error}</Alert> : null}*/}

                        <div className="mb-3">
                          <Label className="form-label">Login</Label>
                          <Input
                              name="email"
                              className="form-control"
                              placeholder="example@mail.com"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                          />
                          {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            {/*<Link to="/forgot-password" className="text-muted">დაგავიწყდათ პაროლი?</Link>*/}
                          </div>
                          <Label className="form-label">Password</Label>
                          <Input
                              name="password"
                              value={validation.values.password || ""}
                              type="password"
                              placeholder="*******"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                          />
                          {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>


                        <div className="mt-3">
                          <button
                              className="btn btn-warning w-100 waves-effect waves-light"
                              type="submit"
                          >
                            Sign In
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                          {/*<p className="mb-0">არ ხართ დარეგისტირებული ?*/}
                          {/*  <Link to="/register" className="fw-medium text-primary"> რეგისტრაცია</Link>*/}
                          {/*</p>*/}
                        </div>

                      </Form>

                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} {process.env.REACT_APP_PROJECT_NAME}
                  </p>
                </div>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
  )
}

export default withRouter(Login);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}
