import React, {useState, useEffect} from 'react';
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap';

const PaginationComponent = ({skip, limit, handlePageClick, totalItems}) => {

    const [totalPages, setTotalPages] = useState(0)
    useEffect(() => {
        if (totalItems > 0) {
            let tr = Math.ceil(totalItems / limit);
            setTotalPages(tr);
        } else {
            setTotalPages(0);
        }
    }, [totalItems, limit]);

    if (totalPages <= 1 || totalItems === 0) {
        return null; // Возвращает null, чтобы скрыть пагинацию
    }
    return (
        <Pagination aria-label="Page navigation example">
            <PaginationItem disabled={skip === 0}>
                <PaginationLink href="#" onClick={() => handlePageClick(skip - limit)} tabIndex="-1">
                    Prev
                </PaginationLink>
            </PaginationItem>
            {Array.from({length: totalPages}).map((_, index) => (
                <PaginationItem key={index} active={index * limit === skip}>
                    <PaginationLink href="#" onClick={() => handlePageClick(index * limit)}>
                        {index + 1}
                        {index * limit === skip && <span className="sr-only">(current)</span>}
                    </PaginationLink>
                </PaginationItem>
            ))}
            <PaginationItem disabled={skip + limit >= totalItems}>
                <PaginationLink href="#" onClick={() => handlePageClick(skip + limit)}>
                    Next
                </PaginationLink>
            </PaginationItem>
        </Pagination>
    );
};

export default PaginationComponent;


