import {
    GET_ALL_INFORMATION_REQUEST_SUCCESS, GET_DASHBOARD_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    registryAll: 0,
    donorAll: 0,
}

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_INFORMATION_REQUEST_SUCCESS:
            return {
                ...state,
                registryAll : action.callBack.dashboard.registryAll,
                donorAll : action.callBack.dashboard.donorAll
            }
 /*       case GET_DASHBOARD_REQUEST_SUCCESS:

            return {
                ...state,

                transactionArray : action.callBack.dashboard.transactions,
                usersFizArray : action.callBack.dashboard.usersFiz,
                usersIurArray : action.callBack.dashboard.usersIur,
                usersIndArray : action.callBack.dashboard.usersInd
            }
*/
        default:
            return state
    }
}

export default reducer
