import React, {useEffect, useState} from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Container, Alert, Spinner, CardTitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";

import FeatherIcon from "feather-icons-react";
import ImgContainer from "./imgContainer";
import PaginationComponent from "../../components/Pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Events = (props) => {
    document.title = " Donors | CRM"

    const history = useNavigate();

    const candidate = useSelector((state) => state.authorization.candidate);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(20)


    useEffect(() => {
        setIsLoading(true)
        let item = {
            skip: 0,
            limit: limit,
        }

        async function fetchData() {
            await getDonors(item);
        }

        fetchData();
    }, []);

    async function getDonors(item) {
        try {
            let jwt = localStorage.getItem("token")
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/donor/getAll/${item.skip}/${item.limit}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`,
                }
            });
            const Data = await response.json();

            if (!Data.success) {
                setError(true)
            } else {
                setData(Data.result)
                setDataCount(Data.resultCount)
            }

        } catch (error) {
            setError(true)
            throw error;
        } finally {
            setIsLoading(false);
        }
    }


    async function deleteDonor(item) {
        try {
            let jwt = localStorage.getItem("token")
            setData(prevData => prevData.filter(obj => obj._id !== item.id));
            setIsLoading(true)
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/donor/delete`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`,
                },
                body: JSON.stringify(item)
            });
            const Data = await response.json();

            if (!Data.success) {
                setError(true)
            } else {
                setData(prevData => prevData.filter(obj => obj._id !== item.id));
            }

        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        const item = {
            skip: skip.toString(),
            limit: limit.toString(),
        };
        getDonors(item);
        // dispatch(getIngredients(queryParams))
    }, [skip, limit])

    const handlePageClick = (newSkip) => {
        setSkip(newSkip);
    };

    //========
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [id, setId] = useState({})
    const handleOpenAlertModal = (id, key) => {
        setId(id)
        setconfirm_alert(true)
    }


    const handleRemove = () => {
        let item = {
            id
        }
        deleteDonor(item)
        setconfirm_alert(false)
    }


    const openEditPage = (id) => {
        history(`/edit-donor/${id}`);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="" breadcrumbItem="Donors List"/>
                    <Row>
                        <Col lg={12}>

                            {
                                isLoading ? (
                                    <Row className="justify-content-center align-items-center">
                                        <Col lg={12} className="text-center">
                                            <Spinner className="m-1" color="primary"/>
                                        </Col>
                                    </Row>
                                ) : error ? (
                                    <div className="col-span-full ">
                                        <Alert color="danger">Error</Alert>
                                    </div>
                                ) : (
                                    <>

                                        <Col xl={3} lg={4} sm={6} className="mb-2">
                                            {confirm_alert ? (
                                                <SweetAlert
                                                    title="Are you sure you want to delete this?!"
                                                    warning
                                                    showCancel
                                                    confirmButtonText="Yes, delete it!"
                                                    confirmBtnBsStyle="success"
                                                    cancelBtnBsStyle="danger"
                                                    onConfirm={handleRemove}
                                                    onCancel={() => setconfirm_alert(false)}
                                                >

                                                </SweetAlert>
                                            ) : null}
                                        </Col>
                                        {
                                            data.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>

                                                        <Row>
                                                            <Col xl={12}>
                                                                <Card>
                                                                    <CardBody>


                                                                        <CardTitle
                                                                            className="d-flex justify-content-between align-items-center">
                                                                            <h3>
                                                                                #{item.donorId} | {item.name},
                                                                                Age {item.age}
                                                                            </h3>

                                                                            {
                                                                                candidate.role === 'admin' && <div
                                                                                    className="d-flex align-items-center">
                                                                                <span
                                                                                    style={{cursor: 'pointer'}}
                                                                                    onClick={() => {
                                                                                        openEditPage(item._id)
                                                                                    }}
                                                                                    className="px-3 text-primary"
                                                                                >
                                                                                  <FeatherIcon icon="edit"
                                                                                               className="icon-xs me-2 text-pending"/>
                                                                                </span>
                                                                                    <span
                                                                                        style={{cursor: 'pointer'}}
                                                                                        onClick={() => handleOpenAlertModal(item._id)}
                                                                                        className="text-danger font-size-24 px-3"
                                                                                    >
                                                                                  <i className="uil-trash"></i>
                                                                                </span>
                                                                                </div>
                                                                            }
                                                                        </CardTitle>

                                                                        <p className="card-title-desc mb-1">

                                                                            <Button color="secondary" style={{
                                                                                marginRight: "5px",
                                                                                marginTop: '5px'
                                                                            }} disabled={true} className="waves-effect">
                                                                                Height: {item.height} CM
                                                                            </Button>
                                                                            <Button color="secondary" style={{
                                                                                marginRight: "5px",
                                                                                marginTop: '5px'
                                                                            }} disabled={true} className="waves-effect">
                                                                                Weight: {item.weight} KG
                                                                            </Button>
                                                                            <Button color="secondary" style={{
                                                                                marginRight: "5px",
                                                                                marginTop: '5px'
                                                                            }} disabled={true} className="waves-effect">
                                                                                BloodType: {item.bloodType}
                                                                            </Button>
                                                                            <Button color="secondary" style={{
                                                                                marginRight: "5px",
                                                                                marginTop: '5px'
                                                                            }} disabled={true} className="waves-effect">
                                                                                Hair Color: {item.hairColor}
                                                                            </Button>
                                                                            <Button color="secondary" style={{
                                                                                marginRight: "5px",
                                                                                marginTop: '5px'
                                                                            }} disabled={true} className="waves-effect">
                                                                                Eye Color: {item.eyeColor}
                                                                            </Button>

                                                                            {
                                                                                candidate.role === 'admin' &&
                                                                                <Button color="secondary" style={{
                                                                                    marginRight: "5px",
                                                                                    marginTop: '5px'
                                                                                }} disabled={true}
                                                                                        className="waves-effect">
                                                                                    SocMedia: {item.socMedia}
                                                                                </Button>
                                                                            }

                                                                        </p>

                                                                        <div className="popup-gallery">
                                                                            <Row>
                                                                                {
                                                                                    item.images.map((it, key) => {
                                                                                        return (
                                                                                            <React.Fragment key={key}>
                                                                                                <ImgContainer
                                                                                                    key={key}
                                                                                                    images={item.images}
                                                                                                    it={it}/>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Row>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )
                                            })
                                        }


                                        <PaginationComponent
                                            skip={skip}
                                            limit={limit}
                                            totalItems={dataCount}
                                            handlePageClick={handlePageClick}
                                        />
                                    </>
                                )
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

}

export default Events

