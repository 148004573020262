import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Card,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
    FormFeedback,
} from "reactstrap"

import Dropzone from "react-dropzone";
import {notification} from 'antd';
import {useFormik} from "formik"
import * as Yup from "yup"

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link} from "react-router-dom";
import {ErrorResult, SuccessResult} from "../../components/Notifications/Messages";


const AddPartner = (props) => {

    document.title = "Add user"


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            login: '',
            password: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            login: Yup.string().required("Please Enter login"),
            password: Yup.string().required("Please Enter password"),
        }),
        onSubmit: (values) => {
            let item = {
                login: values.login,
                password: values.password,
                role : 'user',
                name : values.name
            }
            createUser(item)
        }
    })


    async function createUser(item) {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/auth/signup`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(item)

            });
            const Data = await response.json();

            if (!Data.result) {
                ErrorResult('error')
            } else {
                SuccessResult('success')
            }

        } catch (error) {
            ErrorResult('error')
            throw error;
        } finally {
            // setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="" breadcrumbItem="Add User"/>

                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                    >
                        <Row>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label className="form-label">Name</Label>
                                    <Input
                                        name="name"
                                        className="form-control"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback
                                            type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label className="form-label">Login</Label>
                                    <Input
                                        name="login"
                                        className="form-control"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.login || ""}
                                        invalid={
                                            validation.touched.login && validation.errors.login ? true : false
                                        }
                                    />
                                    {validation.touched.login && validation.errors.login ? (
                                        <FormFeedback
                                            type="invalid">{validation.errors.login}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label className="form-label">Password</Label>
                                    <Input
                                        name="password"
                                        className="form-control"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.password || ""}
                                        invalid={
                                            validation.touched.password && validation.errors.password ? true : false
                                        }
                                    />
                                    {validation.touched.password && validation.errors.password ? (
                                        <FormFeedback
                                            type="invalid">{validation.errors.password}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>


                        <Row>
                            <Col lg="6">
                                <button
                                    className="btn btn-primary w-100 waves-effect waves-light"
                                    type="submit"
                                >
                                    Add User
                                </button>
                            </Col>
                        </Row>
                    </Form>


                </Container>
            </div>
        </React.Fragment>
    );


}

export default AddPartner

