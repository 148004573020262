import {notification} from 'antd';
import {withTranslation} from "react-i18next";
import i18n from "../../i18n"


export const signInEmailOrPasswordIncorrect = type => {
    notification[type]({
        message: 'Error',
        description: 'Login or Password incorrect',
    });
};
