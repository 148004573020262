import React, {useEffect, useState} from "react"
import {
    Card,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
    FormFeedback, Button,
} from "reactstrap"

import Dropzone from "react-dropzone";
import {useFormik} from "formik"
import * as Yup from "yup"

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link} from "react-router-dom";
import {ErrorResult, SuccessResult} from "../../components/Notifications/Messages";
import commingsoon from "../../assets/images/donation.png";


const Blogs = (props) => {

    document.title = "Add Donation"

    const [step, setStep] = useState(false)
    const [selectedFilesErr3, setSelectedFilesErr3] = useState(false)
    const [selectedFiles, setselectedFiles] = useState([])

    useEffect(() => {
        setStep(true)
    }, [])

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    function handleAcceptedFiles(files) {
        files.map(file => {
                if (file.size >= 3022117) {
                    setSelectedFilesErr3(true)
                    setselectedFiles([])
                    return false
                } else {
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        formattedSize: formatBytes(file.size),
                    })
                }
            }
        )
        setselectedFiles(files)
    }


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            age: '',
            height: '',
            weight: '',
            bloodType: '',
            hairColor: '',
            eyeColor: '',
            socMedia: '',
            phone: '',
            comment: '',
        },
        validationSchema: Yup.object({
            /*       name: Yup.string().required("Please Enter Name"),
                   age: Yup.string().required("Please Enter Age"),
                   height: Yup.string().required("Please Enter Height"),
                   weight: Yup.string().required("Please Enter Weight"),
                   hairColor: Yup.string().required("Please Enter Hair Colour"),
                   eyeColor: Yup.string().required("Please Enter Eye Colour"),
                   phone: Yup.string().required("Please Enter Phone"),*/
        }),
        onSubmit: (values) => {
            let item = {
                name: values.name,
                age: values.age,
                height: values.height,
                weight: values.weight,
                bloodType: values.bloodType,
                hairColor: values.hairColor,
                eyeColor: values.eyeColor,
                socMedia: values.socMedia,
                phone: values.phone,
                comment: values.comment,
            }

            let formData = new FormData();

            selectedFiles.forEach((file, index) => {
                formData.append('files', file);
            });
            formData.append('inputs', JSON.stringify(item));

            createDonor(formData)
        }
    })


    async function createDonor(item) {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/donor/create`, {
                method: "POST",
                body: item
            });
            const Data = await response.json();

            if (!Data.success) {
                ErrorResult('error')
            } else {
                SuccessResult('success')
                setStep(false)
                setSelectedFilesErr3(false)
                setselectedFiles([])
                validation.resetForm();
            }

        } catch (error) {
            ErrorResult('error')
            throw error;
        } finally {
            // setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="" breadcrumbItem="Add Donation"/>

                    {
                        step
                            ?
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >

                                <Row>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">Name</Label>
                                            <Input
                                                name="name"
                                                className="form-control"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.name || ""}
                                                invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                                <FormFeedback
                                                    type="invalid">{validation.errors.name}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">Age</Label>
                                            <Input
                                                name="age"
                                                className="form-control"
                                                type="number"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.age || ""}
                                                invalid={
                                                    validation.touched.age && validation.errors.age ? true : false
                                                }
                                            />
                                            {validation.touched.age && validation.errors.age ? (
                                                <FormFeedback
                                                    type="invalid">{validation.errors.age}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">Height</Label>
                                            <Input
                                                name="height"
                                                className="form-control"
                                                type="number"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.height || ""}
                                                invalid={
                                                    validation.touched.height && validation.errors.height ? true : false
                                                }
                                            />
                                            {validation.touched.height && validation.errors.height ? (
                                                <FormFeedback
                                                    type="invalid">{validation.errors.height}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">Weight</Label>
                                            <Input
                                                name="weight"
                                                className="form-control"
                                                type="number"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.weight || ""}
                                                invalid={
                                                    validation.touched.weight && validation.errors.weight ? true : false
                                                }
                                            />
                                            {validation.touched.weight && validation.errors.weight ? (
                                                <FormFeedback
                                                    type="invalid">{validation.errors.weight}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label
                                                className="form-label">Blood Type </Label>
                                            <select
                                                name="bloodType"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.bloodType || ""}
                                                className="form-control m-input">
                                                <option value="">Select One</option>
                                                <option value="1-">1-</option>
                                                <option value="1+">1+</option>

                                                <option value="2-">2-</option>
                                                <option value="2+">2+</option>

                                                <option value="3-">3-</option>
                                                <option value="3+">3+</option>

                                                <option value="4-">4-</option>
                                                <option value="4+">4+</option>

                                            </select>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">Hair Colour</Label>

                                            <select
                                                name="hairColor"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.hairColor || ""}
                                                className="form-control m-input">
                                                <option value="">Select One</option>
                                                <option value="black">Black</option>
                                                <option value="brown">Brown</option>
                                                <option value="blonde">Blonde</option>
                                                <option value="beige">Beige</option>
                                            </select>

                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">Eye Colour</Label>

                                            <select
                                                name="eyeColor"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.eyeColor || ""}
                                                className="form-control m-input">
                                                <option value="">Select One</option>
                                                <option value="blue">Blue</option>
                                                <option value="green">Green</option>
                                                <option value="brown">Brown</option>
                                                <option value="black">Black</option>
                                                <option value="mixed">Mixed</option>
                                            </select>

                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">Soc Media</Label>
                                            <Input
                                                name="socMedia"
                                                className="form-control"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.socMedia || ""}
                                                invalid={
                                                    validation.touched.socMedia && validation.errors.socMedia ? true : false
                                                }
                                            />
                                            {validation.touched.socMedia && validation.errors.socMedia ? (
                                                <FormFeedback
                                                    type="invalid">{validation.errors.socMedia}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">Phone</Label>
                                            <Input
                                                name="phone"
                                                className="form-control"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.phone || ""}
                                                invalid={
                                                    validation.touched.phone && validation.errors.phone ? true : false
                                                }
                                            />
                                            {validation.touched.phone && validation.errors.phone ? (
                                                <FormFeedback
                                                    type="invalid">{validation.errors.phone}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>

                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label className="form-label">Comment</Label>
                                            <Input
                                                name="comment"
                                                className="form-control"
                                                type="textarea"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.comment || ""}
                                            />
                                        </div>
                                    </Col>

                                    <Col md={12}>
                                        <div className="mb-3">
                                            <Dropzone
                                                accept={{
                                                    'image/png': [],
                                                    'image/jpeg': [],
                                                }}
                                                onDrop={acceptedFiles => {
                                                    handleAcceptedFiles(acceptedFiles)
                                                }}
                                            >
                                                {({getRootProps, getInputProps}) => (
                                                    <div className="dropzone">
                                                        <div
                                                            style={{
                                                                fontSize: '17px'
                                                            }}
                                                            className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <p>Upload Photos</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            {
                                                selectedFilesErr3
                                                    ?
                                                    <span
                                                        style={{color: 'red'}}><i> File size should not exceed 3 MB</i></span>
                                                    : null
                                            }
                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                {selectedFiles.map((f, i) => {
                                                    return (
                                                        <Card
                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                            key={i + "-file"}
                                                        >
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col className="col-auto">
                                                                        <img
                                                                            data-dz-thumbnail=""
                                                                            height="80"
                                                                            className="avatar-sm rounded bg-light"
                                                                            alt={f.name}
                                                                            src={f.preview}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted font-weight-bold"
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col lg="6">
                                        <button
                                            className="btn btn-warning w-100 waves-effect waves-light"
                                            type="submit"
                                        >
                                            Add
                                        </button>
                                    </Col>
                                </Row>
                            </Form>

                            : <>
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center">
                                            <Row className="justify-content-center mt-5">
                                                <Col lg={4} sm={5}>
                                                    <div className="maintenance-img">
                                                        <img src={commingsoon} alt="" className="img-fluid mx-auto d-block" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <h4 className="mt-5">Profile created successfully</h4>


                                            <Row className="justify-content-center mt-5">
                                                <Col lg={6}>
                                                    <div className="col-auto">
                                                        <Button type="button"
                                                                onClick={()=>setStep(true)}
                                                                color="warning" className="w-md waves-effect waves-light">Again</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>



                                </Row>
                            </>
                    }


                </Container>
            </div>
        </React.Fragment>
    );


}

export default Blogs

