import React, { useState } from 'react';
import {
    Col,
} from "reactstrap"
import Lightbox from 'react-image-lightbox'; // Ensure you have the correct Lightbox library

const ImgContainer = ({ images, it, key }) => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isGallery, setIsGallery] = useState(false);
    const handleImageClick = (index) => {
        setPhotoIndex(index);
        setIsGallery(true);
    };
    return (
        <React.Fragment>
            {isGallery && (
                <Lightbox
                    mainSrc={`${process.env.REACT_APP_UPLOADER_SERVER_URL}/uploads/images/blog/${images[photoIndex]}`}
                    nextSrc={`${process.env.REACT_APP_UPLOADER_SERVER_URL}/uploads/images/blog/${images[(photoIndex + 1) % images.length]}`}
                    prevSrc={`${process.env.REACT_APP_UPLOADER_SERVER_URL}/uploads/images/blog/${images[(photoIndex + images.length - 1) % images.length]}`}
                    onCloseRequest={() => setIsGallery(false)} // This should close the Lightbox
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                    imageCaption={`Project ${photoIndex + 1}`}
                />
            )}

            <Col xl={2} md={4} className="col-6">
                <div className="mt-4">
                    <div className="img-fluid">
                        <img
                            style={{ height: '160px', width: '100%' }}
                            src={`${process.env.REACT_APP_UPLOADER_SERVER_URL}/uploads/images/blog/${it}`}
                            onClick={() => handleImageClick(images.indexOf(it))}
                            alt=""
                            className="img-fluid d-block cursor-pointer"
                        />
                    </div>
                </div>
            </Col>
        </React.Fragment>
    );
}

export default ImgContainer;
