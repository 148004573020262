import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Row,
    Col,
    Card,
    CardBody,
    Label,
    Button,
    Container, Alert, Spinner,
} from "reactstrap"
import {notification} from 'antd';
import ObjectColumns from './ObjectColums'
import Breadcrumbs from "../../components/Common/Breadcrumb";
let jwt = localStorage.getItem("token")

const Events = (props) => {
    const dispatch = useDispatch();

    document.title = " Users | CRM"

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);

    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(1000)


    useEffect(() => {
        let item = {
            skip: 0,
            limit: limit,
        }

        async function fetchData() {
            await getLeads(item);
        }

        fetchData();
    }, []);

    async function getLeads(item) {
        try {
            setIsLoading(true)
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/users/getAll/${item.skip}/${item.limit}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`,
                }
            });
            const Data = await response.json();

            if (!Data.success) {
                setError(true)
            } else {
                setData(Data.result)
            }

        } catch (error) {
            setError(true)
            throw error;
        } finally {
            setIsLoading(false);
        }
    }

    const handleDelete = (item) => {
        deleteBlog(item)
    }


    async function deleteBlog(item) {
        try {
            setIsLoading(true)
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/users/delete`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`,
                },
                body: JSON.stringify(item)
            });
            const Data = await response.json();

            if (!Data.success) {
                setError(true)
            } else {
                setData(prevData => prevData.filter(obj => obj._id !== item.id));
            }

        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="" breadcrumbItem="Users"/>
                    <Row>
                        <Col lg={12}>
                            {
                                isLoading ? (
                                    <Row className="justify-content-center align-items-center">
                                        <Col lg={12} className="text-center">
                                            <Spinner className="m-1" color="primary"/>
                                        </Col>
                                    </Row>
                                ) : error ? (
                                    <div className="col-span-full ">
                                        <Alert color="danger">Error</Alert>
                                    </div>
                                ) : (
                                    <>
                                        <ObjectColumns
                                            // onRowSelect={onRowSelect}
                                            // onSelectAll={onSelectAll}
                                            // pagination={handlePagination}
                                            handleDelete={handleDelete}
                                            array={data}
                                        />
                                    </>
                                )
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xl={2}>

                                    {/*  <ExcelFile
                                        element={<button className='waves-effect waves-light btn btn-primary'>Download
                                            EXEL files</button>}>
                                        <ExcelSheet data={props.user.users} name="Employees">
                                            <ExcelColumn label="User Id" value="userUnicId"/>
                                            <ExcelColumn label="Email" value="email"/>
                                            <ExcelColumn label="fullName" value="fullName"/>
                                            <ExcelColumn label="ip" value="ip"/>
                                            <ExcelColumn label="country" value="country"/>
                                            <ExcelColumn label="phone" value="phone"/>
                                            <ExcelColumn label="Сompaign Id" value="compaignId"/>
                                        </ExcelSheet>
                                    </ExcelFile>*/}
                                </Col>
                                <Col xl={2}>
                                    {/*  <button
                                        onClick={handleSendApi}
                                        className='waves-effect waves-light btn btn-success'>
                                        Send leads to....
                                    </button>*/}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>


                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={3}>
                                            <div className="mb-3">
                                                <Label className="form-label">Campaigns </Label>
                                                <select
                                                    // onChange={(e)=>setQuesten1(e.target.value)}
                                                    className="form-control">
                                                    <option value={'0'}>All Campaigns</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="mb-3">
                                                <Label className="form-label"> Date </Label>
                                                <select
                                                    // value={date}
                                                    // onChange={(e) => setDate(e.target.value)}
                                                    className="form-control">
                                                    <option value={'all'}> All Time</option>
                                                    <option value={'today'}> Today</option>
                                                    <option value={'yesterday'}> Yesterday</option>
                                                    <option value={'lastweek'}> Last Week</option>
                                                    <option value={'last30day'}> Last 30 day</option>
                                                    <option value={'custom'}>Custom date range</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="mb-3">
                                                <Label className="form-label"> Country </Label>
                                                {/*<select
                                                    onChange={(e) => setCountry(e.target.value)}
                                                    className="form-control">
                                                    <option value={'all'}> All</option>
                                                    {
                                                        props.filter.country.length > 0
                                                            ?
                                                            <>
                                                                {
                                                                    props.filter.country.map((item, key) =>{
                                                                        return (
                                                                            <option key={key} value={item.name}>{item.name}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </>
                                                            : null
                                                    }

                                                </select>*/}
                                            </div>
                                        </Col>

                                        <Col md={2}>
                                            <div className="mb-3">
                                                <Label className="form-label"> Traffic source </Label>
                                                <select
                                                    // onChange={(e) => setTrafficsource(e.target.value)}
                                                    className="form-control">
                                                    <option value={'all'}> All</option>
                                                    {/*  {
                                                        props.filter.trafficsource.length > 0
                                                            ?
                                                            <>
                                                                {
                                                                    props.filter.trafficsource.map((item, key) =>{
                                                                        return (
                                                                            <option key={key} value={item.name}>{item.name}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </>
                                                            : null
                                                    }
*/}
                                                </select>
                                            </div>
                                        </Col>

                                        <Col md={2}>
                                            <div className="mt-4" style={{paddingTop: '5px'}}>
                                                <Button
                                                    // onClick={handleSearch}
                                                    type="button" color="primary" className="w-md"> Search </Button>
                                            </div>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            {/* <ObjectColumns
                                onRowSelect={onRowSelect}
                                onSelectAll={onSelectAll}
                                pagination={handlePagination}
                                usersArray={props.user.users}
                            />*/}
                        </Col>
                    </Row>
                </div>
            </div>

        </React.Fragment>
    )
}
/*const mapStateToProps = state => {
    const {
        user,
        filter
    } = state
    return {
        user,
        filter
    }
};*/
export default Events

