import {
    LOGIN_ADMIN_REQUEST,
    CHECK_ADMIN_REQUEST
} from "./actionTypes"

export const loginAdmin = (data) => ({
    type: LOGIN_ADMIN_REQUEST,
    payload :data
})
export const checkAdmin = (data) => ({
    type: CHECK_ADMIN_REQUEST,
    payload :data
})

/*

export const logOutOwnerUser = (data) => ({
    type: LOGOUT_USER_REQUEST,
    payload :data
})

export const registerOwnerUser = (data) => ({
    type: REGISTER_USER_REQUEST,
    payload :data
})

export const checkOwnerLoginUser = (data) => ({
    type: CHECK_USER_LOGIN_STATUS_REQUEST,
    payload :data
})
export const checkOwnerUser = (data) => ({
    type: CHECK_USER_STATUS_REQUEST,
    payload :data
})

export const forgotPasswordOwner = (data) => ({
    type: FORGET_PASSWORD_REQUEST,
    payload :data
})
*/
